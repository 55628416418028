import { ParseError } from 'effect/ParseResult';
import { Either, pipe } from 'effect';
import { Either as EitherT } from 'effect/Either';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type ToSet<T> = Array<{ key: keyof T; value: T[keyof T] }>;
export type ToUpsert<T> = ToSet<T>;
export type ToDelete<T> = keyof T | Array<keyof T>;

export const useQueryStringFilters = <
  DecodedFilterState extends Record<string, unknown>,
  EncodedFilterState extends Record<string, string>
>({
  defaultFilterValues,
  encode,
  decode,
}: {
  defaultFilterValues?: EncodedFilterState;
  encode: (decodedState: DecodedFilterState) => EitherT<EncodedFilterState, ParseError>;
  decode: (encodedState: EncodedFilterState) => EitherT<DecodedFilterState, ParseError>;
}) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams(new URLSearchParams(defaultFilterValues));

  const getQueryFilters = useCallback((): DecodedFilterState => {
    const currentEncodedState = Object.fromEntries(urlSearchParams.entries()) as EncodedFilterState;
    return pipe(
      decode(currentEncodedState),
      Either.map((filters) => {
        if (filters.page && typeof filters.page === 'number') {
          return { ...filters, page: filters.page - 1 };
        }
        return filters;
      }),
      Either.getOrElse((error) => {
        console.error('Error decoding filter values:', error);
        return {} as DecodedFilterState;
      })
    );
  }, [urlSearchParams, decode]);

  const setQueryFilters = useCallback(
    (toSet: ToSet<DecodedFilterState>) => {
      const maybePayload = toSet.reduce<DecodedFilterState>((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      }, {} as DecodedFilterState);

      const result = encode(maybePayload);
      if (Either.isRight(result)) {
        const payload = result.right;
        setUrlSearchParams(payload, { replace: true, preventScrollReset: true });
        return;
      }
      throw result.left;
    },
    [encode, setUrlSearchParams]
  );

  const upsertQueryFilters = useCallback(
    (toUpsert: ToUpsert<DecodedFilterState>) => {
      const maybePayload = toUpsert.reduce<DecodedFilterState>((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      }, {} as DecodedFilterState);
      const withCurrentFilters = { ...getQueryFilters(), ...maybePayload };
      const result = encode(withCurrentFilters);
      if (Either.isRight(result)) {
        const payload = result.right;
        setUrlSearchParams(payload, { replace: true, preventScrollReset: true });
        return;
      }
      throw result.left;
    },
    [encode, getQueryFilters, setUrlSearchParams]
  );

  const deleteQueryFilters = useCallback(
    (key?: ToDelete<DecodedFilterState>) => {
      if (typeof key === 'string') {
        urlSearchParams.delete(key);
      }
      if (Array.isArray(key)) {
        key.forEach((key) => typeof key === 'string' && urlSearchParams.delete(key));
      }
      if (key == null) {
        const entries = Array.from(urlSearchParams.entries());

        for (const [_key] of entries) {
          urlSearchParams.delete(_key);
        }
      }
      setUrlSearchParams(urlSearchParams);
    },
    [setUrlSearchParams, urlSearchParams]
  );

  useEffect(() => {
    setUrlSearchParams((queryParams) => {
      for (const param of queryParams) {
        queryParams.set(param[0], param[1]);
      }
      return queryParams;
    });
  }, [setUrlSearchParams]);

  const stabilizedReturnValues = useMemo(
    () => ({
      urlSearchParams,
      setUrlSearchParams,
      getQueryFilters,
      setQueryFilters,
      upsertQueryFilters,
      deleteQueryFilters,
    }),
    [deleteQueryFilters, getQueryFilters, setQueryFilters, setUrlSearchParams, upsertQueryFilters, urlSearchParams]
  );

  return stabilizedReturnValues;
};
