import { TFunction } from 'i18next';
import { RouteObject } from 'react-router';
import { ISSUE_STATUS, JOB_STATUS, PRIMITIVES, ROLES } from './constants';
import { ComponentProps, JSXElementConstructor, ReactNode } from 'react';
import { Either, Schema as S, Schema } from 'effect';
import { ParseError } from 'effect/ParseResult';

export type AppDrawerRoute = {
  key: string;
  path: string;
  label: string;
  id?: string;
  children?: AppDrawerRoute[];
  icon?: JSX.Element;
  rolesAllowed?: string[];
};

export enum CustomErrors {
  HIDE_FIELD = 'HIDE_FIELD',
  UNIQUE = 'This field must be unique',
  UPLOAD_FIRST = 'UPLOAD_FIRST',
}

export type EnhancedRouteObject = RouteObject &
  Partial<{
    label: string;
    icon: JSX.Element;
    rolesAllowed: string[];
  }>;

export const _ApiResult = <T extends S.Schema.Any>(data: T) =>
  S.Union(
    ApiFailureResult,
    S.Struct({
      success: S.Literal(true),
      data: data,
    })
  );

export const ApiFailureResult = S.Struct({
  success: S.Literal(false),
  error: S.Unknown,
});

export interface ApiResult<T> {
  success: boolean;
  data?: T;
}

export type ApiState<T extends object> = {
  api: {
    queries: {
      [key in string]: {
        originalArgs?: string;
        data?: ApiResult<T>;
      };
    };
    mutations: {
      [key: string]: {
        startedTimeStamp: number;
        requestId: string;
        endpointName: string;
      };
    };
  };
};

export type CustomAction = {
  actionLabel?: string;
  onActionClick: Function;
};

export type TranslateFunction = TFunction<'translation', undefined, 'translation'>;

export type RGB = {
  r: number;
  g: number;
  b: number;
};

export type RGBA = { a: number } & RGB;

export type HSL = {
  h: number;
  s: number;
  l: number;
};

export type User = {
  firstName: string;
  lastName: string;
  country: string;
  mobilePhoneNumber: string;
  address: string;
  addressExtra: string;
  city: string;
  zip: string;
  state: string;
  countryCode: string | null;
  dealerId: string | null;
  email: string;
  createdAt: string;
  globalRoles: Array<string>;
  updatedAt: string;
  id: string;
  lastLoginAt: string;
  metaData: { [key: string]: string } | null;
  userId: string;
  company: string;
  brandIds: Array<string>;
};

export type Role = (typeof ROLES)[keyof typeof ROLES];

export type Primitive = (typeof PRIMITIVES)[keyof typeof PRIMITIVES];

export type SeverityT = typeof Severity.Type;
export const Severity = Schema.Enums({
  Critical: 'critical',
  NonCritical: 'non-critical',
});

export type StatusT = typeof Status.Type;
export const Status = Schema.Enums({
  Active: 'active',
  Dismissed: 'dismissed',
});

export type IssueStatus = (typeof ISSUE_STATUS)[keyof typeof ISSUE_STATUS];

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = {
  [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
}[Keys];

export type NotEmptyList<T> = [T, ...T[]];

export type Nullable<T> = { [key in keyof T]: T[key] | null };

export type Requires<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type Job = {
  id: string;
  jobId: string;
  serialNumber: string;
  version: string;
  minVersion?: string;
  status: (typeof JOB_STATUS)[keyof typeof JOB_STATUS];
  attempts: number;
  updatedAt: string;
  createdAt: string;
  lastAttemptAt: string | null;
  deliveredAt: string | null;
  completedAt: string | null;
};

export type FieldsUndefined<TBase, TField extends keyof TBase> = Omit<TBase, TField> & Partial<Pick<TBase, TField>>;

export type Slot<K extends string, C extends ReactNode> = {
  [P in K]: C;
};

export type ListToRecord<Keys extends string[], V = {}, Res = {}> = Keys extends [
  infer Head extends string,
  ...infer Rem extends string[]
]
  ? ListToRecord<
      Rem,
      V,
      Res & {
        [P in Head]: V;
      }
    >
  : Res;

export type WithSlots<
  P extends {},
  Slots extends string[] | Record<string, AcceptedComponent>,
  AcceptedComponent extends ((props: AcceptedComponentsProps) => ReactNode) | ReactNode = (props: any) => ReactNode,
  AcceptedComponentsProps = ComponentProps<JSXElementConstructor<any>>
> = P & {
  slots?: Slots extends string[] ? RequireAtLeastOne<ListToRecord<Slots, AcceptedComponent>> : RequireAtLeastOne<Slots>;
};

export type OnlyFirst<F, S> = F & { [Key in keyof Omit<S, keyof F>]?: never };

export type MergeTypes<TypesArray extends any[], Res = {}> = TypesArray extends [infer Head, ...infer Rem]
  ? MergeTypes<Rem, Res & Head>
  : Res;

export type OneOf<TypesArray extends any[], Res = never, AllProperties = MergeTypes<TypesArray>> = TypesArray extends [
  infer Head,
  ...infer Rem
]
  ? OneOf<Rem, Res | OnlyFirst<Head, AllProperties>, AllProperties>
  : Res;

export type DeviceTooltipContentProps = {
  name: string;
  model: string;
  serialNumber: string;
  errors: number;
  coordinates: { lat: number; lng: number; accuracy?: number };
  alarms: {
    [key: string]: number;
  };
  deviceQnumber?: string;
  manufacturingSerialNumber?: string;
};
export type DecodeResult<T> = Either.Either<T, ParseError>;
