import { SerializedError } from '@reduxjs/toolkit';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ApiResult, CustomErrors, DecodeResult } from '@typings';

type UniquenessEndpointRequest<TData, TField extends keyof TData> = {
  [key in TField]: TData[TField];
};

type UniquenessResponse<TField extends PropertyKey> = {
  [key in TField]?: boolean;
};

type IsUniqueTrigger<TData, TField extends keyof TData> = LazyQueryTrigger<
  QueryDefinition<
    UniquenessEndpointRequest<TData, TField>,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    ApiResult<UniquenessResponse<TField>> | DecodeResult<ApiResult<UniquenessResponse<TField>>>,
    'api'
  >
>;

type QueryResponse<TField extends PropertyKey> = {
  error?: FetchBaseQueryError | SerializedError;
  data?: ApiResult<UniquenessResponse<TField>> | DecodeResult<ApiResult<UniquenessResponse<TField>>>;
};

function isErrorOrDecodeFailure<TData, TField extends keyof TData>(
  response: QueryResponse<TField>
): response is
  | { error: FetchBaseQueryError | SerializedError }
  | { data: DecodeResult<ApiResult<UniquenessResponse<TField>>> } {
  if (!response.data) {
    return !!response.error;
  }
  return 'right' in response.data ? false : '_tag' in response.data && response.data._tag === 'Left';
}

function isDecodeResult<T>(data: unknown): data is DecodeResult<T> {
  return typeof data === 'object' && data !== null && '_tag' in data;
}

export default function useUniqueness<TData, TField extends keyof TData>({
  isUniqueTrigger,
}: {
  isUniqueTrigger: IsUniqueTrigger<TData, TField>;
}) {
  const validate = async (field: TField, value?: TData[TField]) => {
    const body = { [field]: value } as UniquenessEndpointRequest<TData, TField>;
    const isUniqueRes = await isUniqueTrigger(body);

    if (isErrorOrDecodeFailure(isUniqueRes)) {
      // TODO - Handle the possible error
      // TODO - Maybe with a notification
      return;
    }

    if (!isUniqueRes.data) {
      return;
    }

    const isFieldUnique = isDecodeResult(isUniqueRes.data)
      ? isUniqueRes.data._tag === 'Right' && !!isUniqueRes.data.right.data?.[field]
      : !!isUniqueRes.data.data?.[field];

    return isFieldUnique ? true : CustomErrors.UNIQUE;
  };

  return validate;
}
