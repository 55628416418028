import { Device } from '@/components/Fleet/Devices/DevicesPanel/typings';
import usePolling from '@/hooks/usePolling';
import { useLazyGetDeviceOperatingModeAndPowerProfileQuery } from '@/redux/api/admin/deviceCommandsApiSlice';
import apiSlice from '@/redux/api/apiSlice';
import { Operation, STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { removePolling, sendPollingUpdate } from '@/redux/slices/pollingSlice';
import { OPERATION_STATUS } from '@/shared/constants';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PollProps } from './typings';
import { removePendingCommand } from '@/redux/slices/deviceSlice';
import { OperatingMode } from '@culligan-iot/domain/culligan/device/class/base';

export default function PollOperatingModeCommand({ polling, id }: PollProps) {
  const lastArgs = polling?.lastArgs as { operatingMode: OperatingMode; serialNumber: Device['serialNumber'] };
  const requestedOperatingMode = lastArgs?.operatingMode;
  const dispatch = useDispatch();
  const { result, isPolling, stop, remainingAttempts } = usePolling({
    useQuery: useLazyGetDeviceOperatingModeAndPowerProfileQuery,
    interval: polling?.interval,
    maxAttempts: polling.maxAttempts,
    pollingState: polling,
    queryArgs: lastArgs.serialNumber,
  });

  const partialOperation = useMemo(
    () =>
      ({
        entity: lastArgs.serialNumber,
        location: ' ',
        operationId: id,
        read: false,
        showed: false,
        subject: lastArgs.operatingMode || (t('unknownEntityXXX', { entity: t('device') }) as string),
        timestamp: dayjs().valueOf(),
        tag: TAGS.DEVICE_COMMAND_SEND,
      } satisfies Partial<Operation>),

    [id, lastArgs.operatingMode, lastArgs.serialNumber]
  );

  const handleRemovePolling = useCallback(() => {
    dispatch(removePolling({ endpoint: 'postOperatingModeCommand', id }));
  }, [dispatch, id]);

  const handleRemoveCommand = useCallback(() => {
    dispatch(
      removePendingCommand({
        serialNumber: lastArgs.serialNumber,
        command: 'operating_mode.set',
      })
    );
  }, [dispatch, lastArgs.serialNumber]);

  const handleUpdatePolling = useCallback(() => {
    dispatch(
      sendPollingUpdate({
        endpoint: 'postOperatingModeCommand',
        result: result.data?.data.operatingMode,
        isPolling,
        id,
        remainingAttempts: remainingAttempts,
      })
    );
  }, [dispatch, id, isPolling, remainingAttempts, result.data?.data.operatingMode]);

  const handleRefreshDevice = useCallback(
    () =>
      dispatch({
        type: `${apiSlice.reducerPath}/invalidateTags`,
        payload: ['Device'],
      }),
    [dispatch]
  );

  const handleErrorNotification = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.REJECTED,
        status: STATUS.ERROR,
        uniqueId: `${id}-${STATUS.ERROR}`,
      })
    );
  }, [dispatch, id, partialOperation]);

  const handleFullfilledNotification = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.FULFILLED,
        status: STATUS.SUCCESS,
        uniqueId: `${id}-${STATUS.SUCCESS}`,
      })
    );
  }, [dispatch, id, partialOperation]);

  const handlePollingSuccess = useCallback(() => {
    stop();
    handleFullfilledNotification();
    handleRemovePolling();
    handleRemoveCommand();
    handleRefreshDevice();
  }, [handleFullfilledNotification, handleRefreshDevice, handleRemoveCommand, handleRemovePolling, stop]);

  const handlePollingError = useCallback(() => {
    stop();
    handleErrorNotification();
    handleRemovePolling();
    handleRemoveCommand();
    handleRefreshDevice();
  }, [handleErrorNotification, handleRefreshDevice, handleRemoveCommand, handleRemovePolling, stop]);

  useEffect(() => {
    handleUpdatePolling();
    if (remainingAttempts <= 0 || result.isError) {
      handlePollingError();
    }

    if (result.data?.data.operatingMode === 'Fault' || result.error) {
      handlePollingError();
    }

    if (result.data?.data.operatingMode === requestedOperatingMode && isPolling) {
      handlePollingSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling, result.data?.data.operatingMode, result.error]);

  return <></>;
}
