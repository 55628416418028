/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-redeclare */

import { ApiResult, StatusT } from '@typings';
import { isNotEmptyList } from '@/shared/validations';
import apiSlice, { API_VERSION } from '../apiSlice';
import { Schema as S } from 'effect';
import { FleetOverview } from '@/components/Fleet/Overview/typings';
import { queryStringOfList } from '@/shared/utils';

const FilterKeys = {
  brands: 'brands',
  models: 'models',
  status: 'status',
  levels: 'levels',
} as const;
type FilterKey = (typeof FilterKeys)[keyof typeof FilterKeys];

type GetFiltersResponse = typeof GetFiltersResponse.Type;
const GetFiltersResponse = S.Array(
  S.Union(
    S.TaggedStruct(FilterKeys.brands, { brands: S.Array(S.Struct({ id: S.String, name: S.String })) }),
    S.TaggedStruct(FilterKeys.models, { models: S.Array(S.Struct({ id: S.String, name: S.String })) }),
    S.TaggedStruct(FilterKeys.status, { status: S.Array(S.Literal('connected', 'disconnected')) }),
    S.TaggedStruct(FilterKeys.levels, {
      levels: S.Array(S.Literal('lessThan20', '20To40', '40To60', '60To80', 'moreThan80')),
    })
  )
);

type GetFiltersParams = { keys: Array<FilterKey> } | undefined;

type FleetOverviewResponse = {
  devices: FleetOverview.Device[] | [];
  alarmsDefinitions: FleetOverview.AlarmDefinition[] | [];
};

type FleetOverviewParams = {
  customer?: string;
  model?: string;
  status?: StatusT;
  brand?: string;
};

export const fleetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFleet: builder.query<ApiResult<FleetOverviewResponse>, FleetOverviewParams>({
      query: (params) => ({
        url: `${API_VERSION.v2}/fleet`,
        params,
      }),
    }),
    getFleetFilters: builder.query<ApiResult<GetFiltersResponse>, GetFiltersParams>({
      query: (params) => {
        const list = params?.keys;
        if (isNotEmptyList(list)) {
          return { url: `${API_VERSION.v2}/fleet/filters?${queryStringOfList('keys')(list)}` };
        }
        return { url: `${API_VERSION.v2}/fleet/filters` };
      },
    }),
    getFleetCustomer: builder.query<
      ApiResult<{ customer: { id: string; firstName: string; lastName: string } }>,
      { customerId: string }
    >({
      query: (params) => {
        return {
          url: `${API_VERSION.v2}/fleet/customer`,
          params,
        };
      },
    }),
  }),
});

export const { useGetFleetQuery, useGetFleetCustomerQuery, useGetFleetFiltersQuery } = fleetApiSlice;
