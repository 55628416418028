import { Box, ButtonBase, Chip, Typography, alpha } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useMemo, useState } from 'react';
import { HistoryEventGroupedByDay } from '../typings';
import SlidingInButton from './SlidingInButton';
import useDebounce from '@/hooks/useDebounce';
import AnimatedStack from './AnimatedStack';
import { ZoomingPaper } from './AnimatedPaper';
import EventCard from './EventCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dayjs from 'dayjs';
import { stringToColor } from '@/shared/utils';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import React from 'react';

export default function Card({ data: { totals, day, events } }: { data: HistoryEventGroupedByDay }) {
  const [layout, setLayout] = useState<'collapsed' | 'expanded'>('collapsed');
  const [mouseIn, setMouseIn] = useState<boolean>(false);
  const deboucedMouseIn = useDebounce<boolean>(setMouseIn, 500);

  const eventsCard = useMemo(() => {
    return events.map((event, index) => {
      const color = stringToColor(event.name);
      return (
        <Box key={event.id + index}>
          <EventCard event={event} color={color} animate={layout === 'expanded'} />
        </Box>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, layout]);

  const totalsChips = useMemo(() => {
    const keys = new Set(Object.keys(totals));
    return [...keys.keys()].map((key) => {
      const color = stringToColor(key);
      return {
        id: key,
        component: (amount: number) => (
          <Chip
            key={key}
            sx={{
              backgroundColor: alpha(color, 0.5),
              backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0))',
              border: '1px solid rgba(255,255,255,0.3)',
              boxShadow: `0px 3px 4px 0px ${alpha(color, 0.1)}`,
            }}
            label={
              <Typography>
                <Typography fontWeight="600" component="span">
                  {amount}
                </Typography>{' '}
                {key}
              </Typography>
            }
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totItems = useMemo(() => Object.values(totals).reduce((acc, tot) => acc + tot, 0), [totals]);

  const handleMouseEnter = () => {
    deboucedMouseIn(true, 0);
  };
  const handleMouseLeave = () => {
    deboucedMouseIn(false);
  };

  return (
    <Box>
      {totItems > 0 && (
        <AnimatedStack
          animate={mouseIn}
          lockIn={layout === 'expanded'}
          totItems={totItems}
          events={eventsCard}
          event={{ totals, day, events }}
        >
          <Box
            sx={{ width: 'fit-content', height: '100%' }}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            {totItems > 0 && layout === 'collapsed' && (
              <SlidingInButton
                slide={mouseIn}
                delay={150}
                sx={{ zIndex: 1 }}
                onClick={() => setLayout('expanded')}
                body={
                  <>
                    Click to expand <ChevronRightIcon />
                  </>
                }
              />
            )}
            {totItems > 0 && layout === 'expanded' && (
              <SlidingInButton
                slide={mouseIn}
                delay={150}
                sx={{ zIndex: 1 }}
                onClick={() => setLayout('collapsed')}
                body={
                  <>
                    <ChevronLeftIcon /> Click to collapse
                  </>
                }
              />
            )}
            <ButtonBase
              sx={{
                height: '100%',
                outline: 'none',
                border: 0,
                '&:focus': { outline: 'none', border: 0 },
                '&:focus-visible': { outline: 'none', border: 0 },
              }}
            >
              <ZoomingPaper
                delay={150}
                animate={mouseIn}
                sx={{
                  position: 'relative',
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '446px',
                  gap: 2,
                  minHeight: '200px',
                  height: '100%',
                  ...(totItems > 0
                    ? {
                        cursor: 'pointer',
                        '& *': {
                          cursor: 'pointer',
                        },
                      }
                    : {}),
                }}
                onClick={() =>
                  setLayout((prev) => {
                    if (prev === 'collapsed') {
                      return 'expanded';
                    }
                    return 'collapsed';
                  })
                }
              >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box display="flex" gap={0.5}>
                    <Typography fontWeight="600" variant="h5">
                      {totItems}
                    </Typography>
                    <Typography variant="h5">{' events'}</Typography>
                  </Box>
                  <Chip
                    variant="filled"
                    size="small"
                    label={dayjs(day).format('L')}
                    avatar={<CalendarTodayIcon sx={{ p: 0.3 }} />}
                  />
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', gap: 1, width: '100%', flexWrap: 'wrap' }}>
                    {Object.keys(totals).map((key, index) => {
                      const chip = totalsChips.find((c) => c.id === key)?.component(totals[key]);
                      return (
                        <React.Fragment key={key + index}>
                          <RenderIf condition={!!chip}>
                            <Box>{chip}</Box>
                          </RenderIf>
                          <RenderIf condition={!chip}>
                            <Typography>Missing chip</Typography>
                          </RenderIf>
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </Box>
              </ZoomingPaper>
            </ButtonBase>
          </Box>
        </AnimatedStack>
      )}
      {totItems === 0 && <Box sx={{ height: '50px' }} />}
    </Box>
  );
}
