import { Id as DeviceID } from '@culligan-iot/domain/culligan/device/class/base';
import { Schema as S } from 'effect';
import { getTableFilters } from '@/components/Shared/Tables/utils';
import { TableRef } from '@/components/Shared/Tables/typings';
import { makeConsumablesSchema } from '@culligan-iot/domain/culligan/device/class/_util';
import { Kind, KIND } from '@/shared/constants';
export namespace FleetConsumables {
  export const INITIAL_PAGE = 1 as const;
  export const INITIAL_PAGE_SIZE = 10 as const;
  export const DEBOUNCE_TIME = 400 as const;
  export const INITIAL_KIND = KIND.enums.Enhancements;

  export type FilterParams = typeof FilterParams.Type;
  const FilterParams = S.transform(S.Struct({ fields: S.String }), S.Array(S.String), {
    encode: (l) => ({ fields: l.join(',') }),
    decode: (o) => o.fields.split(','),
  });
  export const encodeFilterParams = S.encodeSync(FilterParams);

  export const LevelNames = S.Literal('lessThan20', 'from20To40', 'from40To60', 'from60To80', 'moreThan80');

  export const LevelRanges = {
    [LevelNames.pipe(S.pickLiteral('lessThan20')).toString()]: [Number.MIN_VALUE, 20],
    [LevelNames.pipe(S.pickLiteral('from20To40')).toString()]: [20, 40],
    [LevelNames.pipe(S.pickLiteral('from40To60')).toString()]: [40, 60],
    [LevelNames.pipe(S.pickLiteral('from60To80')).toString()]: [60, 80],
    [LevelNames.pipe(S.pickLiteral('moreThan80')).toString()]: [80, Number.MAX_VALUE],
  };

  export const DeviceFieldKeys = {
    Model: 'model',
    Brand: 'brand',
    Customer: 'customer',
    Level: 'level',
    Kind: 'kind',
  } as const;

  export const TablePaginationKeys = {
    Page: 'page',
    Size: 'size',
    OrderBy: 'orderBy',
    Direction: 'direction',
  } as const;

  export const DeviceFilterKeys = {
    ...DeviceFieldKeys,
    ...TablePaginationKeys,
  } as const;

  export type Device = typeof Device.Type;
  export const Device = S.Struct({
    id: DeviceID,
    model: S.String,
    name: S.String,
    serialNumber: S.String,
    consumables: makeConsumablesSchema([] as const),
  });

  export type Columns = typeof Columns.Type;
  const Columns = S.Union(S.keyof(Device.pick('model')), S.Literal('levels'));

  const TableFilters = getTableFilters(Columns);
  const BarFilters = S.Struct({
    [DeviceFieldKeys.Model]: S.optional(S.String),
    [DeviceFieldKeys.Brand]: S.optional(S.String),
    [DeviceFieldKeys.Customer]: S.optional(S.String),
    [DeviceFieldKeys.Level]: S.optional(LevelNames),
    [DeviceFieldKeys.Kind]: S.optional(KIND),
  });

  const Filters = S.Struct({ ...TableFilters.fields, ...BarFilters.fields });

  export const encodeFilters = S.encodeEither(Filters);
  export const decodeFilters = S.decodeUnknownEither(Filters);

  export type DecodedFilters = typeof Filters.Type;
  export type EncodedFilters = typeof Filters.Encoded;
  export type FilterKey = keyof DecodedFilters;

  export type Table = TableRef<Device>;

  export type ShoppingDialogDetails = {
    devices: Device[];
    consumableType: Kind | 'all';
  };

  export type ShoppingDialogProps = ShoppingDialogDetails & {
    onClose: Function;
  };
}
