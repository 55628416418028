import { useCallback, useEffect, useMemo } from 'react';
import { PollProps } from './typings';
import { useDispatch } from 'react-redux';
import usePolling from '@/hooks/usePolling';
import { Polling, removePolling, sendPollingUpdate } from '@/redux/slices/pollingSlice';
import { Operation, STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { COMMANDS, OPERATION_STATUS } from '@/shared/constants';
import dayjs from 'dayjs';
import { useLazyGetDeviceLogLevelQuery } from '@/redux/api/admin/deviceCommandsApiSlice';
import apiSlice from '@/redux/api/apiSlice';
import { removePendingCommand } from '@/redux/slices/deviceSlice';
import { Id, LogLevel } from '@culligan-iot/domain/culligan/device/class/base';

export default function PollLogLevel({ polling, id }: PollProps) {
  const { lastArgs } = polling as Omit<Polling, 'lastArgs'> & {
    lastArgs?: { requestedLogLevel: LogLevel; serialNumber: Id };
  };
  const requestedLogLevel = lastArgs?.requestedLogLevel;
  const { result, isPolling, stop, remainingAttempts } = usePolling({
    useQuery: useLazyGetDeviceLogLevelQuery,
    interval: polling?.interval,
    maxAttempts: polling.maxAttempts,
    pollingState: polling,
    queryArgs: lastArgs?.serialNumber,
  });
  const latestLogLevel = result.data?.data?.logLevel;

  const dispatch = useDispatch();

  const partialOperation = useMemo(
    () =>
      ({
        entity: lastArgs?.serialNumber || '',
        location: ' ',
        operationId: id,
        read: false,
        showed: false,
        subject: COMMANDS.LOG_SET,
        timestamp: dayjs().valueOf(),
        tag: TAGS.DEVICE_COMMAND_SEND,
      } satisfies Partial<Operation>),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, lastArgs]
  );

  const handleRemovePolling = useCallback(() => {
    dispatch(removePolling({ endpoint: 'postLogLevel', id }));
    dispatch(
      removePendingCommand({
        serialNumber: lastArgs?.serialNumber as string,
        command: 'log.set',
      })
    );
  }, [dispatch, id, lastArgs]);

  const handlePollingSuccess = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.FULFILLED,
        status: STATUS.SUCCESS,
        uniqueId: `${id}-${STATUS.SUCCESS}`,
      })
    );
    dispatch(
      removePendingCommand({
        serialNumber: lastArgs?.serialNumber as string,
        command: 'log.set',
      })
    );
    handleRemovePolling();
  }, [dispatch, handleRemovePolling, id, lastArgs, partialOperation]);

  const handlePollingError = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.REJECTED,
        status: STATUS.ERROR,
        uniqueId: `${id}-${STATUS.ERROR}`,
        error: result.error,
      })
    );
    handleRemovePolling();
  }, [dispatch, handleRemovePolling, id, partialOperation, result.error]);

  useEffect(() => {
    dispatch(sendPollingUpdate({ endpoint: 'postLogLevel', result, isPolling, id, remainingAttempts }));
    if ((!isPolling && result.error) || remainingAttempts <= 0) {
      handlePollingError();
      return;
    }

    if (
      polling.remainingAttempts &&
      polling.remainingAttempts > 0 &&
      isPolling &&
      requestedLogLevel === latestLogLevel
    ) {
      handlePollingSuccess();
      stop();
      dispatch({
        type: `${apiSlice.reducerPath}/invalidateTags`,
        payload: ['Device'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, isPolling, dispatch, stop]);

  return <></>;
}
