import Area, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader, DataBoundAreaRow } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import { LogLevelCommandPayload } from '@/redux/api/admin/deviceCommandsApiSlice';
import ChatIcon from '@mui/icons-material/Chat';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import TryIcon from '@mui/icons-material/Try';
import { useTheme } from '@mui/material';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from '../../../DevicesPanel/typings';
import { LogLevel as LogLevelT } from '@culligan-iot/domain/culligan/device/class/base';
import AreaButton from '@/components/Shared/Card/Area/AreaButton';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
function LogLevel({
  device,
  postLogLevel,
  isLoadingLogLevel,
  isInLoadingState,
  requestLogLevel,
}: {
  device: Device;
  postLogLevel: MutationTrigger<LogLevelCommandPayload & any>;
  isLoadingLogLevel: boolean;
  isPollingLogLevel: boolean;
  isInLoadingState: boolean;
  requestLogLevel?: LogLevelT;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const LEVELS: Array<{
    value: LogLevelT;
    icon: React.ElementType;
    color: string;
    label: string;
  }> = useMemo(
    () => [
      { value: 'Silent', icon: SpeakerNotesOffIcon, color: theme.palette.grey[800], label: t('Silent') },
      { value: 'Debug', icon: ChatIcon, color: theme.palette.success.light, label: t('Debug') },
      { value: 'Warning', icon: FeedbackIcon, color: theme.palette.warning.light, label: t('Warning') },
      { value: 'Trace', icon: TryIcon, color: theme.palette.error.light, label: t('Trace') },
    ],
    [t, theme.palette.error.light, theme.palette.grey, theme.palette.success.light, theme.palette.warning.light]
  );
  const LEVELS_WITHOUT_IDENTITY = useMemo(
    () => LEVELS.filter((level) => level.value !== device?.logLevel),
    [LEVELS, device?.logLevel]
  );
  const { internalActionGap } = AREA_DESIGN_TOKENS;
  return (
    <DataBoundAreaRow
      sx={{ display: 'grid', gridTemplate: 'auto / repeat(6, 1fr)', gap: internalActionGap, width: '100%' }}
    >
      <Area
        sx={{
          width: '100%',
          gridColumnStart: '1',
          gridColumnEnd: LEVELS_WITHOUT_IDENTITY.length === 3 ? 4 : 3,
          borderRadius: 1,
        }}
      >
        <AreaHeader>
          <TypographyWithTooltip
            anchorText={t('logLevel')}
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            tooltipText={t('logLevelTooltip')}
          />
        </AreaHeader>
        <AreaBody>
          <Title>{device?.logLevel || t('notAvailable')}</Title>
        </AreaBody>
      </Area>
      {LEVELS_WITHOUT_IDENTITY.map(({ value, icon, color, label }, index) => (
        <AreaButton
          sx={{ borderRadius: 1 }}
          key={value}
          Icon={icon}
          color={color}
          isLoading={isLoadingLogLevel && requestLogLevel === value}
          disabled={[
            { disabled: isInLoadingState, reason: t('pendingCommands') as string },
            { disabled: device.logLevel === value },
            { disabled: device.status.connection.online === false, reason: t('loglevelWhileOffline') as string },
          ]}
          handler={() =>
            postLogLevel({
              serialNumber: device.serialNumber,
              logLevel: value,
            })
          }
          text={label}
        />
      ))}
    </DataBoundAreaRow>
  );
}
export default React.memo(LogLevel);
