import { Schema as S } from 'effect';
import { TelemetryFilters } from '../../typings';

const GroupTelemetry = S.Struct({
  tagName: S.String,
  key: S.Array(S.String),
});

export const Telemetries = S.Struct({
  key: S.optional(S.Array(S.String)),
  tag: S.optional(S.Array(GroupTelemetry)),
});

export type TelemetryFiltersDecoded = typeof TelemetryFiltersDecodedSchema.Type;
const TelemetryFiltersDecodedSchema = S.Struct({
  start: S.optional(S.Number),
  end: S.optional(S.Number),
  telemetries: S.optional(Telemetries),
});

export type TelemetryFiltersEncoded = typeof TelemetryFiltersEncodedSchema.Type;
const TelemetryFiltersEncodedSchema = S.Any;

// eslint-disable-next-line no-useless-escape
const removeBrackets = (qs: string) => qs.match(/[^\[\]]+/g);

/**
 *  from searchParams to telemetries
 */
export const decodeTelemetryFilters = (searchParams: TelemetryFiltersEncoded) => {
  const { start, end, ...telemetriesSearchParams } = searchParams;
  let telemetries;
  const telemetrysSearchParamsEntries = Object.entries(telemetriesSearchParams);

  if (telemetrysSearchParamsEntries.length) {
    telemetries = telemetrysSearchParamsEntries.reduce<TelemetryFilters>((acc, [key, val]) => {
      const token = removeBrackets(key);
      if (token === null || token.length <= 0 || token.shift() !== 'key') {
        return acc;
      }

      // case of e.g. ?key[0]=some_telemetry
      // token is ["some_telemetry"]
      if (token.length === 1) {
        if (!acc.key) {
          acc.key = [val as string];
          return acc;
        } else {
          acc.key.push(val as string);
          return acc;
        }
      }

      // case of e.g. ?key[Temperature][0]=one_of_temperature_telemetry
      // token is ["Temperature", "one_of_temperature_telemetry"]
      if (!acc.tag) {
        acc.tag = [
          {
            tagName: token[0],
            key: [val as string],
          },
        ];

        return acc;
      }

      if (acc.tag) {
        const groupTelemetry = acc.tag.find(({ tagName }: { tagName: string }) => tagName === token[0]);

        if (!groupTelemetry) {
          acc.tag.push({
            tagName: token[0],
            key: [val as string],
          });

          return acc;
        }

        groupTelemetry.key.push(val as string);

        return acc;
      }

      return acc;
    }, {});
  }

  const res =
    telemetries !== undefined
      ? {
          start: Number(start),
          end: Number(end),
          telemetries,
        }
      : {
          start: Number(start),
          end: Number(end),
        };

  return res;
};

type TelemetrySearchParam = `key[${string}]` | `key[${string}][${string}]`;
/**
 *  from telemetries to searchParams
 */
export const encodeTelemetryFilters = (telemetryFilters: TelemetryFiltersDecoded) => {
  const { start, end, telemetries } = telemetryFilters;
  let searchParams: {
    [T in TelemetrySearchParam]?: string;
  } = {};

  if (telemetries) {
    const { key, tag } = telemetries;
    if (key) {
      // single telemetry type
      key.forEach((val, idx) => {
        searchParams[`key[${idx}]`] = val;
      });
    }

    if (tag) {
      // group telemetry type
      tag.forEach(({ tagName, key }, idx) => {
        if (key.length) {
          key.forEach((val, idx) => {
            searchParams[`key[${tagName}][${idx}]`] = val;
          });
        }
      });
    }
  }

  const res = {
    start: start!.toString(),
    end: end!.toString(),
    ...searchParams,
  };
  return res;
};

const FromSearchParamsToTelemetryFilters = S.transform(TelemetryFiltersEncodedSchema, TelemetryFiltersDecodedSchema, {
  strict: false,
  decode: decodeTelemetryFilters,
  encode: encodeTelemetryFilters,
});

export const encode = (telemetryFilters: TelemetryFiltersDecoded) =>
  S.encodeEither(FromSearchParamsToTelemetryFilters)(telemetryFilters);
export const decode = (searchParams: TelemetryFiltersEncoded) =>
  S.decodeUnknownEither(FromSearchParamsToTelemetryFilters, { onExcessProperty: 'preserve' })(searchParams);
