import { Dispatch, Fragment, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import { FleetConsumables } from '@/components/Fleet/Consumables/ConsumablesPanel/typings';
import { ToDelete, ToUpsert } from '@/hooks/useQueryStringFilters';
import { useLazyGetDevicesWithConsumablesQuery } from '@/redux/api/fleet/devicesWithConsumablesApiSlice';
import { Options, OrderByCollection, Query, QueryResult } from '@material-table/core';
import { Direction } from '@/components/Shared/Tables/typings';
import { toOneBasedIndex, toZeroBasedIndex } from '@/shared/utils';
import { useConsumablesDeviceTableColumns } from '@/components/Fleet/Consumables/ConsumablesPanel/useConsumablesDeviceTableColumns';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { Kind } from '@/shared/constants';

export default function ConsumablesPanel({ kind }: { kind: Kind }) {
  const { t } = useTranslation();

  const {
    filters,
    upsertQueryFilters,
    deleteQueryFilters,
    tableRef: table,
    setDevices,
    setSelectedDevices,
  } = useOutletContext<{
    filters: FleetConsumables.DecodedFilters;
    upsertQueryFilters: (toUpsert: ToUpsert<FleetConsumables.DecodedFilters>) => void;
    deleteQueryFilters: (toDelete: ToDelete<FleetConsumables.DecodedFilters>) => void;
    tableRef: MutableRefObject<FleetConsumables.Table>;
    setDevices: Dispatch<SetStateAction<FleetConsumables.Device[]>>;
    setSelectedDevices: Dispatch<SetStateAction<FleetConsumables.Device[]>>;
  }>();

  const { page, size, orderBy, direction, level, model, brand, customer } = filters;

  const columns = useConsumablesDeviceTableColumns(kind);

  const [getDevices] = useLazyGetDevicesWithConsumablesQuery();

  const getPayload = useCallback(
    (query: Query<FleetConsumables.Device>) => {
      const sortCollection = query.orderByCollection.length ? query.orderByCollection[0] : null;
      const field = sortCollection ? columns[sortCollection?.orderBy].field : null;
      const direction = (sortCollection?.orderDirection as Direction) || null;
      const hasSorting = field != null && direction != null;
      const payload: FleetConsumables.DecodedFilters = {
        model: model,
        brand: brand,
        customer: customer,
        level: level,
        kind,
        page: toOneBasedIndex(query.page),
        size: query.pageSize,
        orderBy: hasSorting ? field : (undefined as any),
        direction: hasSorting ? direction : undefined,
      };

      return payload;
    },
    [brand, columns, customer, model, level, kind]
  );

  const handleFetchData = useCallback(
    (query: Query<FleetConsumables.Device>) => {
      return new Promise<QueryResult<FleetConsumables.Device>>((resolve, reject) => {
        getDevices(getPayload(query), true)
          .unwrap()
          .then((result) => {
            const withSafeUniqueId = result?.data?.items.map((d) => ({ idSynonim: d.id + d.model, ...d }));
            const filteredItems = query.search
              ? withSafeUniqueId?.filter((item) =>
                  Object.values(item).some((value) => String(value).toLowerCase().includes(query.search.toLowerCase()))
                )
              : withSafeUniqueId;

            setDevices(filteredItems || []);

            return resolve({
              data: filteredItems || [],
              page: toZeroBasedIndex(result?.data?.page || 1),
              totalCount: result.data?.itemsCount || 0,
            });
          })
          .catch((err) => reject(err));
      });
    },
    [getDevices, getPayload, setDevices]
  );

  const handlePageChange = useCallback(
    (pageFromQuery: number, pageSize: number) => {
      upsertQueryFilters([
        {
          key: FleetConsumables.DeviceFilterKeys.Page,
          value: toOneBasedIndex(typeof page === 'number' ? pageFromQuery : 0),
        },
        { key: FleetConsumables.DeviceFilterKeys.Size, value: pageSize },
      ]);
    },
    [page, upsertQueryFilters]
  );

  const handleOrderChange = useCallback(
    (collections: OrderByCollection[]) => {
      const orderBy = columns[collections?.[0]?.orderBy]?.field as FleetConsumables.Columns;
      const direction = collections?.[0]?.orderDirection as Direction;

      !orderBy || !direction
        ? deleteQueryFilters([FleetConsumables.DeviceFilterKeys.OrderBy, FleetConsumables.DeviceFilterKeys.Direction])
        : upsertQueryFilters([
            { key: FleetConsumables.DeviceFilterKeys.OrderBy, value: orderBy },
            { key: FleetConsumables.DeviceFilterKeys.Direction, value: direction },
          ]);
    },
    [columns, deleteQueryFilters, upsertQueryFilters]
  );

  const options: Options<FleetConsumables.Device> = useMemo(
    () => ({
      idSynonym: 'idSynonim',
      pageSize: size || FleetConsumables.INITIAL_PAGE_SIZE,
      showTitle: false,
      initialPage: page || FleetConsumables.INITIAL_PAGE - 1,
      draggable: false,
      searchDebounceDelay: FleetConsumables.DEBOUNCE_TIME,
      defaultOrderByCollection: [
        { orderBy: columns.findIndex((el) => el.field === orderBy), orderDirection: direction || '', sortOrder: 1 },
      ],
    }),
    [columns, direction, orderBy, page, size]
  );

  useEffect(() => {
    if (!page && !size) {
      upsertQueryFilters([
        { key: FleetConsumables.DeviceFilterKeys.Page, value: FleetConsumables.INITIAL_PAGE },
        { key: FleetConsumables.DeviceFilterKeys.Size, value: FleetConsumables.INITIAL_PAGE_SIZE },
      ]);
    }
  }, [page, size, upsertQueryFilters]);

  return (
    <GenericExportTable<FleetConsumables.Device>
      tableRef={table}
      title={t(kind)}
      isLoading={false}
      data={handleFetchData}
      components={{ OverlayLoading: Fragment }}
      columns={columns}
      onPageChange={handlePageChange}
      onOrderCollectionChange={handleOrderChange}
      options={options}
      exportData={true}
      selection={true}
      onSelectionChange={setSelectedDevices}
    />
  );
}
