import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Schema as S } from 'effect';
import { Either, Option, pipe } from 'effect';
import { DateRangeDefaultValue } from '@/shared/utils';
import { useQueryStringFilters } from '@/hooks/useQueryStringFilters';
import { reduceState } from './reduceState';
import {
  addTelemetryAction,
  changeDateAction,
  removeTelemetryAction,
  DateChangeAction,
  TelemetryFiltersPayload,
  ChangeFilterAction,
} from './actions';
import { Telemetries, TelemetryFiltersDecoded, TelemetryFiltersEncoded, decode, encode } from './transformation';
import { FiltersState } from '../../typings';

const dateRange = S.Struct({
  start: S.Number,
  end: S.Number,
});

const toFiltersMap = (telemetryFilters: FiltersState) => {
  const filtersMap = new Map();
  filtersMap.set(
    'dateRange',
    JSON.stringify({
      start: telemetryFilters.start,
      end: telemetryFilters.end,
    })
  );
  filtersMap.set('telemetries', telemetryFilters.telemetries);
  return filtersMap;
};

export const useTelemetryFilters = () => {
  const dateRangeDefaultValue = useMemo(
    () => new DateRangeDefaultValue(dayjs().subtract(1, 'month').startOf('day'), dayjs().endOf('day')),
    []
  );

  const defaultFilterValues = useMemo(
    () => ({
      start: dateRangeDefaultValue.toUnixAsString().start,
      end: dateRangeDefaultValue.toUnixAsString().end,
    }),
    [dateRangeDefaultValue]
  );

  const { getQueryFilters, setQueryFilters } = useQueryStringFilters<TelemetryFiltersDecoded, TelemetryFiltersEncoded>({
    defaultFilterValues,
    encode,
    decode,
  });

  const buildNextStateMap = (filterAction: ChangeFilterAction) =>
    pipe(reduceState(getQueryFilters() as FiltersState, filterAction), toFiltersMap);

  const handleFiltersApplied = (filterAction: ChangeFilterAction) => {
    const nextFiltersState = buildNextStateMap(filterAction);
    const getFilterApplied = (key: string) => Option.fromNullable(nextFiltersState.get(key));

    const dateRangeFilter = Either.try(() => {
      const dateRange = nextFiltersState.get('dateRange');
      if (dateRange != null) {
        return JSON.parse(dateRange);
      }
      return Either.left('No date range present');
    }).pipe(
      Either.getOrElse(() => []),
      S.decodeUnknownEither(dateRange),
      Either.match({
        onLeft: () => [],
        onRight: (dateAsJSON) => [
          { key: 'start' as const, value: dateAsJSON.start },
          { key: 'end' as const, value: dateAsJSON.end },
        ],
      })
    );

    const telemetries = getFilterApplied('telemetries').pipe(
      Option.getOrNull,
      S.decodeUnknownEither(Telemetries),
      Either.match({
        onLeft: () => [],
        onRight: (right) => [
          {
            key: 'telemetries' as const,
            value: right,
          },
        ],
      })
    );

    const payload = [...dateRangeFilter, ...telemetries];
    if (!payload.length) return;
    return setQueryFilters(payload);
  };

  const handleAddTelemetryFilter = (telemetry: TelemetryFiltersPayload) =>
    pipe(telemetry, addTelemetryAction, handleFiltersApplied);
  const handleRemoveTelemetryFilter = (telemetry: TelemetryFiltersPayload) =>
    pipe(telemetry, removeTelemetryAction, handleFiltersApplied);
  const handleChangeDateRange = (dateRange: DateChangeAction['payload']) =>
    pipe(dateRange, changeDateAction, handleFiltersApplied);

  return { getQueryFilters, handleAddTelemetryFilter, handleRemoveTelemetryFilter, handleChangeDateRange };
};
