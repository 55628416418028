import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryStringFilters } from '@/hooks/useQueryStringFilters';
import { Schema as S } from 'effect';
import { useDispatch } from 'react-redux';

import { fleetApiSlice } from '@/redux/api/fleet/fleetApiSlice';
import { Either, Option } from 'effect';
import { useGetBusinessAnalysisFiltersQuery } from '@/redux/api/businessAnalysis/filtersApiSlice';
import { makeBrandsFilter, makeCustomerFilter, makeDateRangeFilter, makeModelsFilter } from '../Shared/Filters/helpers';
import { DateRangeDefaultValue } from '@/shared/utils';
import dayjs from 'dayjs';

const FILTER_KEYS = {
  BRAND: 'brand',
  MODEL: 'model',
  CUSTOMER: 'customer',
  START: 'start',
  END: 'end',
} as const;

const FiltersSchema = S.Struct({
  start: S.NumberFromString,
  end: S.NumberFromString,
  brand: S.optional(S.String),
  model: S.optional(S.String),
  customer: S.optional(S.String),
});

export type Decoded = typeof FiltersSchema.Type;
type Encoded = typeof FiltersSchema.Encoded;

const encode = S.encodeEither(FiltersSchema);
const decode = S.decodeUnknownEither(FiltersSchema);

export const useBusinessAnalysisFilters = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching, isError } = useGetBusinessAnalysisFiltersQuery();
  const defaultDateRangeValue = useMemo(
    () => new DateRangeDefaultValue(dayjs().subtract(1, 'month').startOf('day'), dayjs().endOf('day')),
    []
  );

  const brands = useMemo(() => data?.data?.items.brands || [], [data]);
  const models = useMemo(() => data?.data?.items.models || [], [data]);
  const { getQueryFilters, setQueryFilters, upsertQueryFilters, deleteQueryFilters } = useQueryStringFilters<
    Decoded,
    Encoded
  >({
    encode,
    decode,
    defaultFilterValues: {
      start: defaultDateRangeValue.toUnixAsString().start,
      end: defaultDateRangeValue.toUnixAsString().end,
      ...(brands?.length === 1 ? { brand: brands[0].id } : {}),
    },
  });
  const { brand, model, customer, start, end } = getQueryFilters();
  const dispatch = useDispatch();

  const fetchInitialCustomer = useCallback(async () => {
    if (!customer) {
      return;
    }
    const { data } = await dispatch(fleetApiSlice.endpoints.getFleetCustomer.initiate({ customerId: customer }));
    const _customer = data?.data?.customer;

    return { label: `${_customer?.firstName} ${_customer?.lastName}`, optionId: _customer?.id || '' };
  }, [dispatch, customer]);

  const maybeBrand = useMemo(
    () =>
      brands?.length
        ? brands
            .filter((b) => b.id === brand)
            .map((b) => ({ label: b.name, optionId: b.id }))
            .at(0)
        : undefined,

    [brand, brands]
  );

  const maybeModel = useMemo(
    () =>
      models?.length
        ? models
            .filter((b) => b.id === model)
            .map((b) => ({ label: b.name, optionId: b.id }))
            .at(0)
        : undefined,

    [models, model]
  );

  const handleFiltersApplied = useCallback(
    (filtersApplied: Map<string, string>) => {
      const getFilterApplied = (key: string) => Option.fromNullable(filtersApplied.get(key));
      const createPayload = (key: keyof Encoded, value: string) => [
        {
          key,
          value,
        } as const,
      ];

      const dateRangeFilter = Either.try(() => {
        const dateRange = filtersApplied.get('dateRange');
        if (dateRange != null) {
          return JSON.parse(dateRange);
        }
        return Either.left('No date range present');
      }).pipe(
        Either.getOrElse(() => []),
        S.decodeUnknownEither(S.Struct({ start: S.Number, end: S.Number })),
        Either.match({
          onLeft: () => [],
          onRight: (dateAsJSON) => [
            { key: FILTER_KEYS.START, value: dateAsJSON.start },
            { key: FILTER_KEYS.END, value: dateAsJSON.end },
          ],
        })
      );

      const brandFilter = getFilterApplied(FILTER_KEYS.BRAND).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.String),
        Either.match({
          onLeft: () => [],
          onRight: (right) => createPayload(FILTER_KEYS.BRAND, right),
        })
      );

      const modelFilter = getFilterApplied(FILTER_KEYS.MODEL).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.String),
        Either.match({
          onLeft: () => [],
          onRight: (right) => createPayload(FILTER_KEYS.MODEL, right),
        })
      );

      const customerFilter = getFilterApplied(FILTER_KEYS.CUSTOMER).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.String),
        Either.match({
          onLeft: () => [],
          onRight: (right) => createPayload(FILTER_KEYS.CUSTOMER, right),
        })
      );

      const payload = [...brandFilter, ...modelFilter, ...customerFilter, ...dateRangeFilter];

      setQueryFilters(payload);
    },
    [setQueryFilters]
  );

  const handleFiltersCleared = useCallback(() => {
    const toClear = new Set(Object.values(FILTER_KEYS));
    const toKeep = new Set([FILTER_KEYS.START, FILTER_KEYS.END, ...[brands.length === 1 ? FILTER_KEYS.BRAND : []]]);
    const asList = Array.from(toClear.difference(toKeep));
    deleteQueryFilters(asList);
    setQueryFilters([
      {
        key: FILTER_KEYS.START,
        value: +defaultDateRangeValue.toUnixAsString().start,
      },
      {
        key: FILTER_KEYS.END,
        value: +defaultDateRangeValue.toUnixAsString().end,
      },
      ...(brands.length === 1 ? [{ key: FILTER_KEYS.BRAND, value: brands[0].id }] : []),
    ]);
  }, [brands, defaultDateRangeValue, deleteQueryFilters, setQueryFilters]);

  const modelsFilter = useMemo(
    () =>
      makeModelsFilter({
        id: FILTER_KEYS.MODEL,
        label: t('deviceName'),
        options:
          models?.map((model) => ({
            label: `${model.name} (${model.id})`,
            optionId: model.id,
          })) || [],
        defaultValue: maybeModel,
      }),
    [models, maybeModel, t]
  );

  const brandsFilter = useMemo(
    () =>
      makeBrandsFilter({
        id: FILTER_KEYS.BRAND,
        label: t('businessUnit'),
        disabled: brands?.length <= 1,
        readOnly: brands?.length <= 1,
        defaultValue: maybeBrand,
        options:
          brands?.map((brand) => ({
            label: brand.name,
            optionId: brand.id,
          })) || [],
      }),
    [brands, maybeBrand, t]
  );

  const customerFilter = useMemo(
    () =>
      makeCustomerFilter({
        id: FILTER_KEYS.CUSTOMER,
        label: t('customer'),
        getInitialValue: fetchInitialCustomer,
      }),
    [fetchInitialCustomer, t]
  );

  const dateRangeFilter = useMemo(
    () =>
      makeDateRangeFilter({
        id: 'dateRange',
        label: t('dateRange'),
        defaultValue: {
          start: start || +defaultDateRangeValue.start,
          end: end || +defaultDateRangeValue.end,
        },
      }),
    [defaultDateRangeValue, end, start, t]
  );

  useEffect(() => {
    if (brands.length === 1 && !brand) {
      upsertQueryFilters([{ key: FILTER_KEYS.BRAND, value: brands[0].id }]);
    }
  }, [brand, brands, upsertQueryFilters]);

  const config = useMemo(
    () => [brandsFilter, modelsFilter, customerFilter, dateRangeFilter],
    [brandsFilter, customerFilter, dateRangeFilter, modelsFilter]
  );

  return useMemo(
    () => ({
      filterConfig: config,
      filtersOptions: data,
      handleFiltersApplied,
      handleFiltersCleared,
      isLoadingFilters: isLoading || isFetching,
      isErrorFilters: isError,
      filters: {
        brand,
        model,
        customer,
        start,
        end,
      },
    }),
    [
      brand,
      config,
      customer,
      data,
      end,
      handleFiltersApplied,
      handleFiltersCleared,
      isError,
      isFetching,
      isLoading,
      model,
      start,
    ]
  );
};
