import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { PowerProfileCommandPayload } from '@/redux/api/admin/deviceCommandsApiSlice';
import { getSupportedPowerProfileActions } from '@/shared/validations';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from '../../../DevicesPanel/typings';
import AreaButton from '@/components/Shared/Card/Area/AreaButton';
import { useOperatingModeColors } from './useOperatingModeColors';
import { OperatingMode } from '@culligan-iot/domain/culligan/device/class/base';
import { PowerProfile as PowerProfileT } from '@culligan-iot/domain/culligan/device/class/base';
import TypographyWithTooltip from '@/components/Shared/Tooltip';

export default function PowerProfile({
  serialNumber,
  operatingMode,
  powerProfile,
  supportedPowerProfiles,
  isDeviceOnline,
  isAdmin,
  postPowerProfile,
  isLoading,
  isInLoadingState,
}: {
  serialNumber: Device['serialNumber'];
  operatingMode: OperatingMode;
  supportedPowerProfiles: PowerProfileT[];
  isDeviceOnline: boolean;
  isLoading: boolean;
  isInLoadingState: boolean;
  isAdmin: boolean;
  postPowerProfile: MutationTrigger<PowerProfileCommandPayload & any>;
  powerProfile?: PowerProfileT;
}) {
  const { t } = useTranslation();
  const operatingModeColors = useOperatingModeColors();
  const supportedPowerProfileActions = useMemo(() => getSupportedPowerProfileActions(powerProfile), [powerProfile]);
  const identitySupportsSleep = useMemo(() => supportedPowerProfiles.includes('Sleep'), [supportedPowerProfiles]);

  return (
    <Fragment>
      <Area
        sx={{
          gridColumnStart: '1',
          borderRadius: 1,
        }}
      >
        <AreaHeader>
          <TypographyWithTooltip
            anchorText={t('powerProfile')}
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            tooltipText={t('powerProfileTooltip')}
          />
        </AreaHeader>
        <AreaBody>
          <Title>{powerProfile || t('notAvailable')}</Title>
        </AreaBody>
      </Area>
      <RenderIf condition={identitySupportsSleep && isAdmin}>
        <AreaButton
          sx={{
            borderRadius: 1,
          }}
          color={() => (powerProfile === 'Standard' ? operatingModeColors.Sleep : operatingModeColors.Standard)}
          disabled={[
            { disabled: !isDeviceOnline, reason: t('deviceOffline') as string },
            { disabled: isLoading, reason: t('powerProfileChangeInProgress') as string },
            { disabled: isInLoadingState, reason: t('pendingCommands') as string },
            {
              disabled: powerProfile !== 'Standard' && !supportedPowerProfileActions.includes('Standard'),
              reason: t('notSupported') as string,
            },
            { disabled: powerProfile === 'Eco', reason: t('cantChangeProfileWhileInEco') as string },
            {
              disabled: operatingMode === 'Disabled',
              reason: t('cantChangeProfileWhileDisabled') as string,
            },
          ]}
          handler={() =>
            postPowerProfile({
              serialNumber: serialNumber,
              powerProfile: powerProfile === 'Standard' ? 'Sleep' : 'Standard',
            })
          }
          Icon={powerProfile === 'Standard' ? BedtimeOutlinedIcon : DeviceHubOutlinedIcon}
          text={powerProfile === 'Standard' ? t('sleep') : t('standard')}
          isLoading={isLoading}
        />
      </RenderIf>
    </Fragment>
  );
}
