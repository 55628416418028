import { useCallback, useEffect, useMemo } from 'react';
import { PollProps } from './typings';
import { useDispatch } from 'react-redux';
import usePolling from '@/hooks/usePolling';
import { removePolling, sendPollingUpdate } from '@/redux/slices/pollingSlice';
import { Operation, STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { COMMANDS, OPERATION_STATUS } from '@/shared/constants';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLazyGetDeviceOperatingModeAndPowerProfileQuery } from '@/redux/api/admin/deviceCommandsApiSlice';
import apiSlice from '@/redux/api/apiSlice';
import { removePendingCommand } from '@/redux/slices/deviceSlice';
import { Id, PowerProfile } from '@culligan-iot/domain/culligan/device/class/base';

export default function PollPowerProfileCommand({ polling, id }: PollProps) {
  const { t } = useTranslation();
  const lastArgs = polling?.lastArgs as { serialNumber: Id; requestedPowerProfile: PowerProfile };
  const command = lastArgs?.requestedPowerProfile === 'Standard' ? COMMANDS.STANDARD : COMMANDS.SLEEP;

  const { result, isPolling, stop, remainingAttempts } = usePolling({
    useQuery: useLazyGetDeviceOperatingModeAndPowerProfileQuery,
    interval: polling?.interval,
    maxAttempts: polling.maxAttempts,
    pollingState: polling,
    queryArgs: lastArgs.serialNumber,
  });
  const latestPowerProfile = result.data?.data?.powerProfile;

  const dispatch = useDispatch();

  const partialOperation = useMemo(
    () =>
      ({
        entity: lastArgs.serialNumber,
        location: ' ',
        operationId: id,
        read: false,
        showed: false,
        subject: command || (t('unknownEntityXXX', { entity: t('device') }) as string),
        timestamp: dayjs().valueOf(),
        tag: TAGS.DEVICE_COMMAND_SEND,
      } satisfies Partial<Operation>),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, lastArgs]
  );

  const handleRemovePolling = useCallback(() => {
    dispatch(removePolling({ endpoint: 'postPowerProfileCommand', id }));
    dispatch(removePendingCommand({ serialNumber: lastArgs.serialNumber, command: command }));
  }, [dispatch, id, command, lastArgs.serialNumber]);

  const handlePollingSuccess = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.FULFILLED,
        status: STATUS.SUCCESS,
        uniqueId: `${id}-${STATUS.SUCCESS}`,
      })
    );
    handleRemovePolling();
  }, [dispatch, handleRemovePolling, id, partialOperation]);

  const handlePollingError = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.REJECTED,
        status: STATUS.ERROR,
        uniqueId: `${id}-${STATUS.ERROR}`,
        error: result.error,
      })
    );
    handleRemovePolling();
  }, [dispatch, handleRemovePolling, id, partialOperation, result.error]);

  useEffect(() => {
    dispatch(sendPollingUpdate({ endpoint: 'postPowerProfileCommand', result, isPolling, id, remainingAttempts }));
    if (remainingAttempts <= 0 || result.isError) {
      handlePollingError();
      return;
    }

    if (
      polling.remainingAttempts &&
      polling.remainingAttempts > 0 &&
      isPolling &&
      result.data?.data &&
      lastArgs.requestedPowerProfile === latestPowerProfile
    ) {
      handlePollingSuccess();
      stop();
      dispatch({
        type: `${apiSlice.reducerPath}/invalidateTags`,
        payload: ['Device'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, isPolling, dispatch, stop]);

  return <></>;
}
